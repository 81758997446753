import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModxAuthors} from "../../../../@backend";

@Component({
  standalone: true,
  selector: 'kodl-single-author',
  imports: [CommonModule],
  template: `
    <span>
  <ng-container *ngIf="author.nickname;then nickname; else name"></ng-container>
  <ng-template #nickname>
  <span>
   {{author.nickname}} <ng-container *ngIf="showDating;then dating"></ng-container>
  </span>
</ng-template>
<ng-template #name>
  <span>
    {{author.firstname}} {{author.lastname}} <ng-container *ngIf="showDating;then dating"></ng-container>
  </span>
</ng-template>
<ng-template #dating>
  <span *ngIf="author.birthday && author.death">
   ({{author.birthday}} - {{author.death}})
  </span>
  <span *ngIf="author.birthday && (author.death == '' || author.death === null)">
   (*{{author.birthday}})
  </span>
  <span *ngIf="author.death && (author.birthday == '' || author.birthday === null)">
   (†{{author.death}})
  </span>
</ng-template>
</span>
  `
})
export class SingleAuthorComponent implements OnInit {

  @Input() author: ModxAuthors;
  @Input() showDating: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
